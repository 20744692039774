// Döviz Kuru

<template>
  <div class="daily-info" v-if="model">
    <div class="item item-icon">
      <img class="effect3" src="/images/currency/dollar.png" />
    </div>
    <div class="item clr-blue shadow">
      <strong>Dolar</strong>
      <span>{{ model.usdValue }}</span>
    </div>
    <div class="item item-icon">
      <img class="effect3" src="/images/currency/euro.png" />
    </div>
    <div class="item clr-purple shadow">
      <strong>Euro</strong>
      <span>{{ model.euroValue }}</span>
    </div>
    <div class="item item-icon">
      <img class="effect3" src="/images/currency/gold.png" />
    </div>
    <div class="item clr-pink shadow">
      <strong>Altın</strong>
      <span>{{ model.goldValue }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'currency1',
  props: ['data'],
  computed: {
    model() {
      return this.$store.state.currency;
    }
  }
};
</script>

<style lang="scss" scoped></style>
